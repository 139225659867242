import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { notification } from 'antd';
import { v4 as uuid } from 'uuid';
import { useRouter } from 'next/router';
import Router from '../routes';
import isWebview from 'is-ua-webview';
import api, { getCurrentUser } from '../utils/api';
import { destroyCookie, getCookie, setCookie } from '../utils/cookies';
import { SettingsContext } from './Settings';
import { LocalizationContext } from './Localization';
import bugsnagClient from '../utils/bugsnag';

export const UserContext = React.createContext();

export const UserProvider = ({ children, user: propsUser }) => {
  const [user, setUser] = React.useState(propsUser);
  const [fbLoginStatus, setFbLoginStatus] = React.useState({status: undefined, authResponse: null});
  const [path, setPath] = React.useState();
  const {
    domainSettings: {
      domain,
      schoolId,
      GDPREnabled,
      google: { clientId: googleOauthClientId },
      facebook: { appId },
    },
    baseUrl,
  } = React.useContext(SettingsContext);
  const { t, locale } = React.useContext(LocalizationContext);

  const router = useRouter();

  React.useEffect(() => {
    setCookie('sync', user ? user.external_id : getCookie('sync') || uuid());
  }, [user]);

  React.useEffect(() => {
    setPath(router.asPath);
  }, [router.asPath]);

  const getTrackingId = () => getCookie('sync');
  const login = async ({ source, params }) => {
    if (user) {
      return;
    }

    if (source === 'facebook') {
      const request = await api
        .post('users/register', {
          id: params.userID,
          provider: source,
          email: params.email,
          first_name: params.first_name,
          last_name: params.last_name,
          referral: params.referral,
          image: params.image,
          portal: domain,
          schoolId,
          GDPREnabled,
        })
        .then(res => res.data)
        .catch(() => null);

      if (request) {
        const { jwt } = request;
        setCookie('token', jwt, {}, false);

        setUser({ ...request, ...(await getCurrentUser()) });
      }
    }

    if (source === 'google') {
      const request = await api
        .post('users/register', {
          id: params.userID,
          provider: source,
          email: params.email,
          first_name: params.first_name,
          last_name: params.last_name,
          image: params.image,
          referral: params.referral,
          portal: domain,
          schoolId,
          GDPREnabled,
          prevTrackingId: getTrackingId(),
        })
        .then(res => res.data)
        .catch(() => null);

      if (request) {
        const { jwt } = request;
        setCookie('token', jwt, {}, false);

        setUser({ ...request, ...(await getCurrentUser()) });
      }
    }
  };

  const logout = () => {
    setCookie('token', null);
    setUser(null);
    if (window.gapi.auth) {
      window.gapi.auth.setToken(null);
      window.gapi.auth.signOut();
    }
  };

  const refreshUser = async () => {
    const data = await getCurrentUser();
    if (data) {
      setUser(data);
    }
  };

  const loginFacebookAuthorizedUser = async ({ onLogin, referral, authResponse }) => {
    return new Promise(async (resolve, reject) => {
      window.FB.api(
        '/me',
        { access_token: authResponse.accessToken, fields: 'id,email,first_name,last_name' },
        async res => {
          const data = {
              ...authResponse,
              ...res
          };
          data.image = `https://graph.facebook.com/${data.userID}/picture?type=square`;
          await login({
              source: 'facebook',
              params: {
                  ...data,
                  referral,
              },
          });

          destroyCookie('referral');

          onLogin(data);
          resolve(data);
      });
    })
  };

  const initLoginStatus = () => {
    window.FB.getLoginStatus(({ status, authResponse }) => {
      setFbLoginStatus({
        status,
        authResponse
      });
    }, true);

    if (window.gapi) {
      window.gapi.load('auth2', () => {
        window.gapi.auth2.init();
      });
    }
  }

  const fbLoginInit = (onLogin, referral, scope) => window.FB.login(
    ({ authResponse }) => {
      if (authResponse) {
        loginFacebookAuthorizedUser({
          onLogin,
          referral,
          authResponse
        });
      }
    },
    { scope },
  );

  const facebookLogin = (onLogin = () => { }, referral = null ) => {
    const scope = 'public_profile,email';

    if (fbLoginStatus.authResponse && fbLoginStatus.authResponse.userID) {
      loginFacebookAuthorizedUser({ onLogin, referral, authResponse: fbLoginStatus.authResponse });
    } else if (isFacebookRedirectMode()) {

      const forwardUrl = window.location.href;
      const redirectUri = `${baseUrl}/auth-callback/facebook`;

      const params = {
        client_id: appId,
        redirect_uri: redirectUri,
        state: forwardUrl,
        scope,
        response_type: "code token",
        auth_type: "",
      };

      const paramsQueryString = new URLSearchParams(params).toString();

      window.location.href = `https://www.facebook.com/dialog/oauth?${paramsQueryString}`;
    } else {
      fbLoginInit(onLogin, referral, scope);
    }
  };

  const isGoogleRedirectMode = () => isWebview(window.navigator.userAgent);
  const isFacebookRedirectMode = () => isWebview(window.navigator.userAgent);

  const getGoogleSignInOptions = () => {
    let signInOptions = {
      client_id: googleOauthClientId,
      scope: 'profile',
      prompt: 'select_account',
      ux_mode: 'popup',
    };

    if (isGoogleRedirectMode()) {
      const forwardUrl = window.location.href
      const redirectUri = `${baseUrl}/auth-callback/google`

      signInOptions = {
        ...signInOptions,
        ux_mode: 'redirect',
        state: forwardUrl,
        redirect_uri: redirectUri
      };
    }

    return signInOptions;
  };

  const loginGoogleUser = async (gUser, onLogin = () => { }, referral = null) => {
    try {
      if (!gUser || !gUser.isSignedIn()) {
        throw new Error('Could not login with google. Please try later');
      }

      const gUserProfile = gUser.getBasicProfile();

      const data = {
        userID: gUserProfile.getId(),
        email: gUserProfile.getEmail(),
        first_name: gUserProfile.getGivenName(),
        last_name: gUserProfile.getFamilyName(),
        image: gUserProfile.getImageUrl(),
        referral,
      };

      await login({
        source: 'google',
        params: data,
      });

      if (isGoogleRedirectMode()) {
        const forwardUrl = gUser.getAuthResponse().state || '/';
        router.push(forwardUrl);
      } else {
        onLogin(data);
      }
    } catch (e) {
      notification.error({
        message: t('warning'),
        description: t('allow.3party.cookies'),
      });

      throw e;
    }
  };

  const googleLogin = async (onLogin = () => { }, referral) => {
    const GoogleAuth = window.gapi.auth2.init();

    const gUser = await GoogleAuth.signIn(getGoogleSignInOptions());

    if (!isGoogleRedirectMode()) {
      return loginGoogleUser(gUser, onLogin, referral);
    }

    return gUser;
  };

  const googleOnRedirectBack = async (onLogin = () => { }, referral = null) => new Promise((resolve, reject) => {
    setTimeout(() => {
      window.gapi.load('auth2', async () => {
        const GoogleAuth = window.gapi.auth2.init();

        GoogleAuth.then(
          googleAuth => {
            const gUser = googleAuth.currentUser.get();

            resolve(loginGoogleUser(gUser, onLogin, referral));
          },
          (error) => {
            reject(error);
          },
        );
      });
    }, 500);
  });

  const facebookOnRedirectBack = async (onLogin = () => { }, referral = null) => new Promise(async (resolve, reject) => {
    const params = new URLSearchParams(window.location.hash.substr(1));
    const userAccessToken = params.get('access_token');
    const errorMessage = params.get('error_message');
    const forwardUrl = params.get('state') || '/';

    try {
      if (!userAccessToken) {
        throw new Error(errorMessage || 'Facebook login failed')
      }

      const response = await api.post('users/fetch-facebook-auth-response', { userAccessToken });

      if (!response || !response.data || !response.data.user_id) {
        throw new Error('Failed to verify user login');
      }

      await loginFacebookAuthorizedUser({
        onLogin,
        referral,
        authResponse: {
          userID: response.data.user_id,
          accessToken: userAccessToken
        }
      });

      resolve("Login finished");
      router.push(forwardUrl);
    } catch (e) {
      reject(e);
      setTimeout(() => {
        router.push(forwardUrl);
      }, 5000);
    }
  });

  const handleAuthCallback = async (source, onLogin = () => { }, referral = null) => {
    try {
      switch (source) {
        case 'google':
          await googleOnRedirectBack(onLogin, referral);
          break;
        case 'facebook':
          await facebookOnRedirectBack(onLogin, referral);
          break;
        default:
          throw new Error(`Unsupported auth source: ${source}`);
      }
    } catch (e) {
      bugsnagClient.notify(e);

      notification.error({
        message: t('warning'),
        description: e.message,
      });
    }

    return user;
  };

  const emailVerify = async ({ email }) => {
    api.post('users/email-verify', {
      email,
      portal: domain,
      locale,
      url: path,
      schoolId,
    });
  };

  const emailRegister = async ({ token, firstName, lastName }) => {
    setCookie('oneTimeToken', token, {}, false);

    const data = await api
      .post('users/email-register', { firstName, lastName, schoolId, GDPREnabled, prevTrackingId: getTrackingId() })
      .then(res => res.data)
      .catch(() => null);

    setCookie('token', data.jwt, {}, false);
    setUser({ ...data, ...(await getCurrentUser()) });
    setCookie('oneTimeToken', null);
  };

  const loginIfExists = async token => {
    setCookie('oneTimeToken', token, {}, false);

    const data = await api
      .post('users/login-if-exists', { prevTrackingId: getTrackingId() })
      .then(res => res.data)
      .catch(() => null);

    if (data && data.firstName && data.lastName) {
      setCookie('token', data.jwt, {}, false);
      setUser({ ...data, ...(await getCurrentUser()) });
      setCookie('oneTimeToken', null);
    }

    return data;
  };

  const redirectIfNotAuthorized = useCallback(() => {
    if (!user) {
      Router.pushRoute('index', { locale });
    }
  }, [user]);

  const updateTestHush = async testResultHash => {
    await api.post('users/updateTestHush', {
      testResultHash,
    });
  };

  if (user) {
    user.fullName = `${user.firstName} ${user.lastName}`;
    if (user.provider === 'facebook') {
      user.picture = `https://graph.facebook.com/${user.providerId}/picture?type=square`;
    }
  }

  return (
    <UserContext.Provider
      value={{
        user,
        login,
        logout,
        facebookLogin,
        googleLogin,
        initLoginStatus,
        handleAuthCallback,
        refreshUser,
        emailVerify,
        emailRegister,
        loginIfExists,
        updateTestHush,
        redirectIfNotAuthorized
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.any,
  user: PropTypes.object,
};

UserProvider.defaultProps = {
  children: null,
  user: null,
};

UserProvider.getInitialProps = async () => {
  process.user = null;
  const token = getCookie('token', false);
  if (!process.browser && token && token !== 'null') {
    try {
      process.user = await getCurrentUser();
    } catch {
      // wrong token
    }

    if (process.user) {
      process.user.schools = (process.user.schools || []).filter(s => s);
    }
  }

  return {
    user: process.user,
  };
};
